import React from "react"
import Container from "@material-ui/core/Container"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import footerStyles from "./footer.module.scss"
import ContactForm from "../contact-formspree"
import InstagramIcon from "@material-ui/icons/Instagram"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import TwitterIcon from "@material-ui/icons/Twitter"

function Footer(props) {
  return (
    <div className={footerStyles.wrapper}>
      <div className={footerStyles.content}>
        <Container className={footerStyles.cta}>
          <h2>Get in touch:</h2>
          <ul className={footerStyles.social_list}>
            <li className={footerStyles.social}>
              <OutboundLink href="https://www.linkedin.com/in/akalsey/">
                <LinkedInIcon style={{ fontSize: 60 }} />
              </OutboundLink>
            </li>
            <li className={footerStyles.social}>
              <OutboundLink href="https://www.instagram.com/alexkalsey/">
                <InstagramIcon style={{ fontSize: 60 }} />
              </OutboundLink>
            </li>
            <li className={footerStyles.social}>
              <OutboundLink href="https://twitter.com/alexkalsey">
                <TwitterIcon style={{ fontSize: 60 }} />
              </OutboundLink>
            </li>
          </ul>
          <ContactForm />
          <p className={footerStyles.footer_copyright}>
            ©{new Date().getFullYear()} Alexander Kalsey
          </p>
        </Container>
      </div>
    </div>
  )
}

export default Footer
