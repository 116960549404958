import React from "react"

import Footer from "./sections/footer.js"
import "../styles/index.scss"

const Layout = ({ children }) => {
  return (
    <div className="container">
      <div className="content">
        <main>{children}</main>
      </div>
      <Footer className="footer" />
    </div>
  )
}

export default Layout
