import React from "react"
import formStyles from "./contact-formspree.module.scss"

export default class MyForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }

  render() {
    const { status } = this.state
    return (
      <div className={formStyles.contactBlock}>
        <form
          onSubmit={this.submitForm}
          action="https://formspree.io/f/xaylagzp"
          method="POST"
          className={formStyles.form}
        >
          <input
            className={formStyles.input}
            type="text"
            name="name"
            placeholder="Name"
          />
          <input
            className={formStyles.input}
            type="email"
            name="_replyto"
            placeholder="Email"
          />
          <textarea
            className={formStyles.input}
            type="text"
            name="message"
            placeholder="Message"
          />
          <input type="text" name="_gotcha" style={{ display: "none" }} />
          {status === "SUCCESS" ? (
            <p className={formStyles.response}>Thanks!</p>
          ) : (
            <button className={formStyles.button}>Submit</button>
          )}
          {status === "ERROR" && (
            <p className={formStyles.response}>
              There was an error, please try again.
            </p>
          )}
        </form>
      </div>
    )
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}
